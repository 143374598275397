<template>
  <div class="accountManagement">
    <el-card style="margin-bottom: 20px" class="top_card">
      <div class="storeCenter_item_top_left">
        <div class="banner_box">
          <div
            class="activity_state"
            :class="['not_started', 'have_in_hand', 'closed'][topDetails.activityState - 1]"
          >
            {{ ['未开始', '进行中', '已结束'][topDetails.activityState - 1] }}
          </div>
          <img v-if="topDetails.activityImg" :src="topDetails.activityImg" alt="" />
          <img v-else src="../../../assets/image/Group 3237.png" alt="" />
        </div>
        <div class="storeCenter_item_top_left_flex">
          <div style="display: flex; align-items: center">
            <h5
              style="
                display: inline-block;
                margin: 0;
                font-size: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                width: 200px;
                -webkit-box-orient: vertical;
              "
            >
              {{ topDetails.activityName }}
            </h5>
            <span class="generalVersion">
              {{ ['通用版', '个性版', '全部'][topDetails.generalVersion * 1] }}</span
            >
            <div class="mark_box">
              <div v-if="topDetails.isPlatform == 1">
                <img src="../../../assets/image/Group 3298.png" alt="" /><span>平台活动</span>
              </div>
              <div v-else>
                <img src="../../../assets/image/展厅-5 3.png" alt="" /><span>渠道活动</span>
              </div>
              <div v-if="topDetails.isMoney">
                <img src="../../../assets/image/Vector-1.png" alt="" /><span>免费</span>
              </div>
              <div v-else>
                <img src="../../../assets/image/Vector-1.png" alt="" /><span>付费</span>
              </div>
              <div v-if="topDetails.activityType == 1">
                <img src="../../../assets/image/Vector.png" alt="" /><span>线上</span>
              </div>
              <div v-else-if="topDetails.activityType == 2">
                <img src="../../../assets/image/Vector(1).png" alt="" /><span>线下</span>
              </div>
            </div>
          </div>
          <div class="storeCenter_item_top_left_flex_tag">
            <span v-for="(o, index) in topDetails.labels" :key="index">
              <span v-if="index < 3" class="office">{{ o }}</span>
            </span>
            <el-popover placement="right" width="430" trigger="hover">
              <div
                class="hidden_label_box"
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                <span
                  class="office"
                  style="
                    border: 1px solid #559eff;
                    color: #4e93fb;
                    box-sizing: border-box;
                    padding: 1px 6px;
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 4px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                  "
                  v-for="(o, index) in topDetails.labelList"
                  :key="index"
                  >{{ o }}</span
                >
              </div>
              <span
                class="office"
                slot="reference"
                v-if="topDetails.labelList && item.labelList.length > 3"
                >{{ topDetails.labelList.length }}+</span
              >
            </el-popover>
          </div>
          <div style="margin-top: 10px">{{ topDetails.sponsor }}</div>
          <p class="address">
            <span style="padding-left: 0px">
              所在地区： {{ topDetails.activityProvince }}-{{ topDetails.activityCity }}</span
            >
            <span>
              活动时间： {{ topDetails.activityStartTime }}至{{ topDetails.activityEndTime }}</span
            >
          </p>
        </div>
      </div>
    </el-card>
    <el-card>
      <el-tabs v-model="queryInfo.type" @tab-click="search">
        <el-tab-pane label="报名成功" name="2">
          <div class="search">
            <div>
              <!-- <el-button type="primary" @click="clickVisibles">新增人员</el-button> -->
              <el-button type="primary" plain @click="exportExcel">导出名单</el-button>
            </div>
            <div class="search_flex">
              <el-form ref="form" :model="queryInfo" label-width="100px">
                <div style="display: flex">
                  <el-form-item label="用户类型:" prop="">
                    <el-radio-group v-model="queryInfo.userLevel" @change="search">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button :label="0">普通用户</el-radio-button>
                      <el-radio-button :label="1">个人会员</el-radio-button>
                      <el-radio-button :label="2">企业会员</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="签到状态:" prop="">
                    <el-radio-group v-model="queryInfo.registerStatus" @change="search">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button :label="1">已签到</el-radio-button>
                      <el-radio-button :label="0">未签到</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <div class="search_right">
                      <el-input
                        placeholder="请输入姓名、企业名称"
                        v-model="queryInfo.queryConditions"
                        class="input-with-select"
                        clearable
                      >
                        <el-button slot="append" @click="search">搜索</el-button>
                      </el-input>
                    </div>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
          <el-table
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            class="table_data"
            :data="tableData"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            ref="multipleTable"
            style="width: 100%"
            tooltip-effect="dark"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
            <el-table-column prop="userId" label="编号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="realName" label="姓名" align="center" width="80">
            </el-table-column>

            <el-table-column label="性别" align="center" width="60">
              <template v-slot="{ row }">
                <div class="sex" v-if="row.sex == '1'">男</div>
                <div class="sex" v-else>女</div>
              </template>
            </el-table-column>

            <el-table-column prop="position" label="职位" align="center"> </el-table-column>
            <el-table-column prop="phone" label="手机号" width="120" align="center">
            </el-table-column>

            <el-table-column prop="companyFullName" label="企业全称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="userLevel" label="用户等级" align="center">
              <template v-slot="{ row }">
                <span v-if="row.userLevel == 0">普通用户</span>
                <span v-if="row.userLevel == 1">个人会员</span>
                <span v-if="row.userLevel == 2">企业会员</span>
              </template>
            </el-table-column>
            <el-table-column prop="ticketName" label="票种" align="center">
              <template v-slot="{ row }">
                <span>{{ row.ticketName }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="purchaseCount"
              label="购买票数(张)"
              align="center"
            ></el-table-column>

            <el-table-column prop="activePrice" label="支付金额" align="center">
              <template v-slot="{ row }">
                <span>{{ row.activePrice }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="updateName" label="审核人" align="center"></el-table-column>
            <el-table-column
              prop="createTime"
              label="报名时间"
              sortable
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column prop="companyFullName" label="操作" align="center">
              <template v-slot="{ row }">
                <span
                  style="color: #4e93fb; cursor: pointer"
                  v-if="row.registerStatus == 0 || row.registerStatus == null"
                  @click="userSignIn(row)"
                  >签到</span
                >
                <span style="color: #ff7d18; cursor: pointer" v-if="row.registerStatus == 1"
                  >已签到</span
                >
                <span
                  style="color: #4e93fb; cursor: pointer; margin-left: 10px"
                  @click="dialoginfo(row)"
                  >详情</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="待审核" name="1">
          <div class="search">
            <div>
              <el-button type="primary" plain @click="exportExcel">导出名单</el-button>
            </div>
            <div class="search_right">
              <el-input
                placeholder="请输入姓名、企业名称"
                v-model="queryInfo.queryConditions"
                class="input-with-select"
                clearable
              >
                <el-button slot="append" @click="search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <el-table
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            class="table_data"
            :data="tableData"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            ref="multipleTable"
            style="width: 100%"
            tooltip-effect="dark"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
            <el-table-column prop="userId" label="编号" align="center"> </el-table-column>
            <el-table-column prop="realName" label="姓名" align="center"> </el-table-column>

            <el-table-column label="性别" align="center">
              <template v-slot="{ row }">
                <div class="sex" v-if="row.sex == '1'">男</div>
                <div class="sex" v-else>女</div>
              </template>
            </el-table-column>

            <el-table-column prop="position" label="职位" align="center"> </el-table-column>
            <el-table-column prop="phone" label="手机号" align="center"> </el-table-column>
            <el-table-column prop="city" label="所在城市" align="center"> </el-table-column>
            <el-table-column prop="companyFullName" label="企业全称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="purchaseCount"
              label="购买票数(张)"
              align="center"
            ></el-table-column>
            <el-table-column prop="updateName" label="审核人" align="center"></el-table-column>
            <el-table-column
              prop="createTime"
              label="报名时间"
              sortable
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template v-slot="{ row }">
              
                  <div class="btn">
                  <span class="pass" @click="passClick(row)">通过</span>
                  <span class="un_pass" @click="unPassClick(row)">不通过</span>
                  <span
                  style="color: #4e93fb; cursor: pointer; margin-left: 10px"
                  @click="dialoginfo(row)"
                  >详情</span
                >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已审核" name="3">
          <div class="search">
            <div class="search_flex">
              <!-- <el-button type="primary" plain @click="handleAdd">导出名单</el-button> -->
              <div>审核状态：</div>
              <el-radio-group v-model="queryInfo.applyStatus" @change="search">
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in InformationTypes"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </div>
            <div class="search_right">
              <el-input
                placeholder="请输入姓名、企业名称"
                v-model="queryInfo.queryConditions"
                class="input-with-select"
                clearable
              >
                <el-button slot="append" @click="search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <el-table
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            class="table_data"
            :data="tableData"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            ref="multipleTable"
            style="width: 100%"
            tooltip-effect="dark"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
            <el-table-column prop="userId" label="编号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="realName" label="姓名" align="center" width="80">
            </el-table-column>

            <el-table-column label="性别" align="center" width="60">
              <template v-slot="{ row }">
                <div class="sex" v-if="row.sex == '1'">男</div>
                <div class="sex" v-else>女</div>
              </template>
            </el-table-column>

            <el-table-column prop="position" label="职位" align="center"> </el-table-column>
            <el-table-column prop="phone" label="手机号" width="120" align="center">
            </el-table-column>

            <el-table-column prop="companyFullName" label="企业全称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="purchaseCount"
              label="购买票数(张)"
              align="center"
            ></el-table-column>
            <el-table-column prop="position" label="审核状态" align="center">
              <template v-slot="{ row }">
                <span v-if="row.applyStatus == 1">待审核</span>
                <span v-if="row.applyStatus == 2">审核通过</span>
                <span v-if="row.applyStatus == 3">拒绝</span>
            
              </template>
            </el-table-column>

            <el-table-column prop="updateName" label="操作人" align="center"></el-table-column>
            <el-table-column
              prop="updateTime"
              label="操作时间"
              sortable
              width="100"
              align="center"
            ></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>

    <!-- 拒绝的理由弹窗 -->
    <el-dialog title="不通过" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="refuse">
        <el-form
          :model="refuseObj"
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-form-item label="理由" prop="refuseReason">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入不通过理由"
              v-model="refuseObj.refuseReason"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增"
      :visible.sync="accountTypeDialogVisibles"
      width="627px"
      class="account_dialog"
    >
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="accountTypeDialogs">
        <div class="input_width">
          <el-form-item label="真实姓名:" prop="realName">
            <el-input v-model="adminUser.realName" placeholder="请输入真实姓名" />
          </el-form-item>

          <el-form-item label="用户性别:" prop="sex">
            <el-radio-group v-model="adminUser.sex">
              <el-radio :label="1">先生</el-radio>
              <el-radio :label="2">女士</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系电话:" prop="phone">
            <el-input v-model="adminUser.phone" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label="公司全称" prop="companyId">
            <el-autocomplete
              v-model="adminUser.companyFullName"
              :fetch-suggestions="
                (res, cb) => {
                  return querySearchAsyncaptname(res, cb)
                }
              "
              placeholder="请输入公司名称"
              @select="
                (res) => {
                  return handleSelectcaptname(res)
                }
              "
            >
              <template slot-scope="{ item }">
                <div class="name">
                  <img
                    :src="item.companyLogo"
                    style="border-radius: 28px"
                    width="28px"
                    height="28px"
                    alt=""
                  />
                  <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                    item.companyFullName
                  }}</span>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="所处职位:" prop="position">
            <el-radio-group v-model="adminUser.position">
              <el-radio :label="'CEO'">CEO</el-radio>
              <el-radio :label="'CIO'">CIO</el-radio>
              <el-radio :label="'IT负责人'">IT负责人</el-radio>
              <el-radio :label="'项目负责人'">项目负责人</el-radio>
              <el-radio :label="'其他'">其他</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitAccountTypeForms()">保存</el-button>
        <el-button @click="accountTypeDialogVisibles = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dialogform" width="840px" class="account_dialog">
      <div class="storeCenter_item_top_left">
        <div class="banner_box">
          <div
            class="activity_state"
            :class="['not_started', 'have_in_hand', 'closed'][topDetails.activityState - 1]"
          >
            {{ ['未开始', '进行中', '已结束'][topDetails.activityState - 1] }}
          </div>
          <img v-if="topDetails.activityImg" :src="topDetails.activityImg" alt="" />
          <img v-else src="../../../assets/image/Group 3237.png" alt="" />
        </div>
        <div class="storeCenter_item_top_left_flex">
          <div style="display: flex; align-items: center">
            <h5
              style="
                display: inline-block;
                margin: 0;
                font-size: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                width: 200px;
                -webkit-box-orient: vertical;
              "
            >
              {{ topDetails.activityName }}
            </h5>
            <span class="generalVersion">
              {{ ['通用版', '个性版', '全部'][topDetails.generalVersion * 1] }}</span
            >
            <div class="mark_box">
              <div v-if="topDetails.isPlatform == 1">
                <img src="../../../assets/image/Group 3298.png" alt="" /><span>平台活动</span>
              </div>
              <div v-else>
                <img src="../../../assets/image/展厅-5 3.png" alt="" /><span>渠道活动</span>
              </div>
              <div v-if="topDetails.isMoney">
                <img src="../../../assets/image/Vector-1.png" alt="" /><span>免费</span>
              </div>
              <div v-else>
                <img src="../../../assets/image/Vector-1.png" alt="" /><span>付费</span>
              </div>
              <div v-if="topDetails.activityType == 1">
                <img src="../../../assets/image/Vector.png" alt="" /><span>线上</span>
              </div>
              <div v-else-if="topDetails.activityType == 2">
                <img src="../../../assets/image/Vector(1).png" alt="" /><span>线下</span>
              </div>
            </div>
          </div>
          <div class="storeCenter_item_top_left_flex_tag">
            <span v-for="(o, index) in topDetails.labels" :key="index">
              <span v-if="index < 3" class="office">{{ o }}</span>
            </span>
            <el-popover placement="right" width="430" trigger="hover">
              <div
                class="hidden_label_box"
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                <span
                  class="office"
                  style="
                    border: 1px solid #559eff;
                    color: #4e93fb;
                    box-sizing: border-box;
                    padding: 1px 6px;
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 4px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                  "
                  v-for="(o, index) in topDetails.labelList"
                  :key="index"
                  >{{ o }}</span
                >
              </div>
              <span
                class="office"
                slot="reference"
                v-if="topDetails.labelList && item.labelList.length > 3"
                >{{ topDetails.labelList.length }}+</span
              >
            </el-popover>
          </div>
          <div style="margin-top: 10px">{{ topDetails.sponsor }}</div>
          <p class="address">
            <span style="padding-left: 0px">
              所在地区： {{ topDetails.activityProvince }}-{{ topDetails.activityCity }}</span
            >
            <span>
              活动时间： {{ topDetails.activityStartTime }}至{{ topDetails.activityEndTime }}</span
            >
          </p>
        </div>
      </div>
      <div class="dialogVisible">
        <div class="dialogVisible_top">
          <div class="username_phone">
            <span>{{ feedinfo.realName }}</span>
            <span style="margin-left: 10px">{{ feedinfo.phone }}</span>
          </div>
          <div class="username_phone">
            <span>{{ feedinfo.companyFullName }}</span>
            <span class="feed_center"></span>
            <span>{{ feedinfo.city }}</span>
          </div>
        </div>
        <div class="feed_info">
          <div class="feed_info_cont" v-for="(item, index) in formlist" :key="index">
            <div class="caption">
              <span>{{ index + 1 }}、{{ item.questionsTitle }}</span>
              <span v-if="item.type == 1">(单选)</span>
              <span v-if="item.type == 3">(多选)</span>
            </div>
            <div class="answer">
              <div v-if="item.type == 2">
                <div class="annex_flex" v-for="(el, indexs) in item.filename" :key="indexs">
                  <i class="el-icon-folder-opened"></i>
                  <span v-if="el" class="annex_name" @click="openPdf(item.answer[indexs])">{{ el }}</span>
                </div>
              </div>
              <div v-if="item.type == 7">
                <div  v-for="(el, indexs) in item.answer" :key="indexs">
                 
                  <span class="annex_name" v-if="el">{{ el }}</span>
                </div>
              </div>
              <div v-if="item.type == 4">
                <div v-for="(el, indexs) in item.answer" :key="indexs">
                  <el-image
                    style="width: 80px; height: 80px; border-radius: 4px"
                    :src="el"
                    :preview-src-list="item.answer"
                  ></el-image>
                </div>
              </div>
              <div v-if="item.type == 1 ||item.type ==3|| item.type == 5 || item.type == 6">
                <div style="margin-top:5px"  v-for="(el, indexs) in item.answer" :key="indexs">
                  <span class="order_num">{{ indexs+1 }}</span>
                  <span v-if="el">{{ el }}</span>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-top: 40px">
        <div  v-if="queryInfo.type==2">
          <el-button type="primary"   v-if="feedinfo.registerStatus == 0 || feedinfo.registerStatus == null" @click="userSignIn(feedinfo)" >签到</el-button>
        <el-button type="info"   v-if="feedinfo.registerStatus == 1"  >已签到</el-button>
        <el-button @click="dialogform = false">取 消</el-button>
        </div>
        <div  v-if="queryInfo.type==1">
          <el-button type="primary"  @click="passClick(feedinfo)" >通过</el-button>
        <el-button type="info"   @click="unPassClick(feedinfo)" >不通过</el-button>
        <el-button @click="dialogform = false">取 消</el-button>
        </div>
        
       
      </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectActivitySignUp,
  refuseUserRegisterActivity,
  auditUserRegisterActivity,
  selectById,
  userSignIn,
  userPushRegister,
  exportExcel,
  selectByActivityId
} from '@/api/activity'
import { selectByCompanyFullName } from '@/api/user.js'
import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  activityId: null,
  orderByParam: 'DESC',
  pageNum: 1,
  pageSize: 10,
  registerStatus: '', //签到状态;0是未签到，1是已签到")
  userLevel: '', //("用户等级;0是普通用户，1是个人会员，2是企业会员")
  type: '2',
  applyStatus: 0
})
const defaultRefuseObj = Object.freeze({
  id: '', //当前用户的id
  refuseReason: '' //拒绝的理由
})
export default {
  name: 'accountManagement',
  mixins: [pagination],

  data() {
    return {
      activityId: this.$route.query.id,
      ids: [],
      rules: {
        refuseReason: [{ required: true, message: '请输入不通过理由', trigger: 'blur' }]
      }, //提交理由的验证表单
      paramrules: {
        monthNum: [{ required: true, message: '请勾选账号周期', trigger: 'change' }]
      },
      dialogVisible: false, //拒绝理由的弹窗
      dialogform: false, //表单弹窗
      feedinfo: {}, //详情弹窗信息
      formlist: [], //表单信息
      open: false, //通过窗口
      refuseObj: {
        id: null,
        refuseReason: null
      },
      // 遮罩层
      loading: true,
      tableData: [], //表格数据
      queryInfo: { ...defaultQueryInfo },
      params: {
        id: null,
        monthNum: null
      },
      topDetails: {}, //头部活动信息
      InformationTypes: [
        {
          value: '全部',
          id: '0'
        },
        {
          value: '已通过',
          id: '2'
        },
        {
          value: '不通过',
          id: '3'
        }
      ],
      adminUser: {
        activityId: '',
        companyId: '',
        phone: '',
        position: '',
        realName: '',
        sex: 0,
        companyFullName: ''
      }, //新增报名信息
      accountTypeDialogVisibles: false, //新增人员弹窗
      adminrules: {
        realName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        companyId: [{ required: true, message: '请输入公司全称', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入企业电话', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern:
              /((13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8})|^(0[0-9]{2,3}-)?([2-9][0-9]{0,9})(-[0-9]{1,9}){0,9}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ]
      },

      issueNameOptions: [] //搜索的企业·1数据
    }
  },

  created() {
    //页面加载完成  调取数据
    this.search()
    this.getSelectById()
  },
  methods: {
    //活动详情
    async getSelectById() {
      console.log(this.activityId)
      const { data: res } = await selectById({ id: this.activityId })

      if (res.resultCode == 200) {
        this.topDetails = res.data
      }
    },
    //公司全称
    async querySearchAsyncaptname(queryString, cb) {
      if (this.adminUser.companyFullName) {
        const res = await selectByCompanyFullName({
          companyFullName: this.adminUser.companyFullName
        })
        if (res.data.resultCode == 200) {
          this.issueNameOptions = res.data.data
          cb(this.issueNameOptions)
        }
      } else {
        cb(this.issueNameOptions)
      }
    },
    //选择企业
    handleSelectcaptname(item) {
      this.adminUser.companyId = item.id
      this.adminUser.companyFullName = item.companyFullName
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    //新增人员保存
    submitAccountTypeForms() {
      this.adminUser.activityId = this.activityId
      this.$refs['accountTypeDialogs'].validate((valid) => {
        if (valid) {
          // 验证通过执行的函数
          userPushRegister(this.adminUser).then(({ data: res }) => {
            if (res.resultCode === 200) {
              this.search()
              this.accountTypeDialogVisibles = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    //批量导出
    async exportExcel() {  
      if(!this.ids.length){
        this.$message.error('请选择需要导出的信息!')
        return
      }
      this.loading = true
      const query = {
        activityId: this.activityId,
        ids: this.ids
      }
      const res = await exportExcel(query)
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `${this.topDetails.activityName}活动报名名单${nowtime}.xlsx` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        this.search()
        this.isIndeterminate = false
        this.checkAll = false
      } else {
        this.$message.error('下载失败!')
      }
    },
    //提交按钮拒绝理由
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { data: res } = await refuseUserRegisterActivity(this.refuseObj)
          if (res.resultCode === 200) {
            this.$message.success('提交成功')
            this.dialogVisible = false
            this.dialogform = false
            this.search()
          } else {
            this.$message.error('拒绝失败')
          }
        } else {
          this.$message.error('请输入拒绝理由')
        }
      })
    },
    //新增人员
    clickVisibles() {
      this.accountTypeDialogVisibles = true
    },
    //关闭拒绝理由的弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          Object.assign(this.refuseObj, defaultRefuseObj)
          done()
        })
        .catch((_) => {})
    },
    //通过审核
    passClick(row) {
      let query = {
        id: row.id
      }

      this.$alert('您是否确认通过？')
        .then(function () {
          return auditUserRegisterActivity(query)
        })
        .then(() => {
          this.search()
          this.$message.success('通过成功')
          this.dialogform = false
        })
        .catch(() => {})
    },
     //查看附件
     openPdf(val){
      window.open(val)
    },
    //签到
    userSignIn(row) {
      let query = {
        id: row.id
      }

      this.$alert('您是否确认签到？')
        .then(function () {
          return userSignIn(query)
        })
        .then(() => {
          this.search()
          this.dialogform=false
          this.$message.success('签到成功')
        })
        .catch(() => {})
    },
    //导出名单
    handleAdd() {},
    //详情弹窗
    async dialoginfo(row) {
      this.dialogform = true
      this.feedinfo = row
      const query = {
        id: this.activityId,
        userId: row.userId
      }
      const { data: res } = await selectByActivityId(query)
      if (res.resultCode === 200) {
        this.formlist = res.data
       
        this.formlist.forEach(el=>{
          let list=[]
          if(el.type==2){
            el.answer.forEach((o,index)=>{
              var indexs= el.answer[index].lastIndexOf("\/")
            let filename  = el.answer[index].substring(indexs + 1, el.answer[index].length);
            list.push(filename)
          })
          this.$set(el, 'filename', list)
          }
         
        })
       
      } else {
        this.$message.error(res.message)
      }
    },
    //不通过审核
    unPassClick(row) {
      this.refuseObj.id = row.id
      this.dialogVisible = true
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },

    //保存首页勾选数据
    getRowKey(row) {
      return row.id
    },
    //获取表格数据
    async search() {
      ;(this.queryInfo.activityId = this.activityId), (this.loading = true)
      const { data: res } = await selectActivitySignUp(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-autocomplete {
  position: relative;
  display: block;
}
.storeCenter_item_top_left {
  display: flex;
  flex-direction: row;
  height: 120.73px;
  padding: 0px 20px;
  .banner_box {
    position: relative;
    width: 175.55px;
    height: 115.73px;
    padding-right: 10px;
    img {
      width: 170.55px;
      height: 117.73px;
      border-radius: 4px;
      margin-right: 18px;
    }
    .activity_state {
      position: absolute;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 34px;
      text-align: center;
      color: #ffffff;
      top: -10px;
      left: -9px;
      width: 78px;
      height: 34px;
      border-radius: 5px 15px 15px 0;
    }
    .not_started {
      background: #4e93fb;
    }
    .have_in_hand {
      background: #ff7d18;
    }
    .closed {
      background: #999999;
    }
  }

  .storeCenter_item_top_left_flex {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .generalVersion {
      background: #448aff;
      border-radius: 2px;
      padding: 5px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      /* identical to box height */
      margin: 0 10px;
      color: #ffffff;
    }
    .mark_box {
      display: flex;
      img {
        width: 14px;
        // height: 14px;
        margin-right: 6px;
        color: #6e6e6e;
      }
      & > div {
        margin-left: 10px;
      }
    }
    .img_commer {
      display: flex;
      border: 1px solid #146aff;
      border-radius: 39px;
      .name {
        font-size: 12px;
        line-height: 20px;
        color: #146aff;
      }
      .color_img {
        background: #146aff;
        height: 20px;
        width: 25px;
        border-radius: 20px;
        position: relative;
        left: -10px;
        img {
          width: 13px;
          height: 13px;
          position: relative;
          left: 5px;
          top: 2px;
        }
      }
    }
    .img_commers {
      display: flex;
      border: 1px solid #ff9c00;
      border-radius: 39px;
      margin: 0px 10px;
      .name {
        font-size: 12px;
        line-height: 20px;
        color: #ff9c00;
      }
      .color_img {
        background: #ff9c00;
        height: 20px;
        width: 25px;
        border-radius: 20px;
        position: relative;
        left: -10px;
        img {
          width: 13px;
          height: 13px;
          position: relative;
          left: 5px;
          top: 2px;
        }
      }
    }

    h4 {
      color: #1f2d3d;
      font-size: 26px;
      font-weight: 500;
      margin: 0;
    }

    .storeCenter_item_top_left_flex_tag {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      padding-top: 13px;
      margin-bottom: 1px;
      width: 500px;
      span {
        box-sizing: border-box;
        display: block;
        font-size: 14px;
        font-weight: 400;
        border-radius: 4px;
      }

      .office {
        border: 1px solid #559eff;
        color: #4e93fb;
        padding: 1px 6px;
        margin-right: 10px;
      }

      .support {
        border: 1px solid #fd523f;
        color: #fd523f;
        margin-left: 12px;
      }
    }

    .address {
      font-size: 14px;
      span {
        padding: 0px 10px;
      }
    }
  }
}
.dialogVisible {
  margin-top: 20px;
  padding-left: 20px;
  .dialogVisible_top {
    .username_phone {
      padding-bottom: 10px;
      font-size: 18px;
      color: #212121;
      font-weight: 550;
      .feed_center {
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        top: 2px;
        display: inline-block;
        width: 0px;
        height: 14.5px;
        border: 2px solid #d4d4d4;
      }
    }
  }
  .feed_info {
    padding-top: 10px;
    .feed_info_cont {
      .caption {
        width: 340px;
        padding: 10px 0px;
        font-size: 18px;
        color: #212121;
      }
      .answer {
        padding: 5px 0px;
        color: #212121;
        .annex_flex {
          display: flex;
          padding: 5px;
          width: 322px;
          border: 1px solid #efefef;
          cursor: pointer;
          .annex_name {
            margin-left: 5px;
            width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .order_num{
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #4e93fb;
          border-radius: 16px;
          color: #fff;
          text-align: center;
          line-height: 16px;
          font-size: 12px;
          margin-right: 10px;
        }
      }
    }
  }
}
.top_card {
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;
    height: 120.73px;

    .banner_box {
      position: relative;
      width: 175.55px;
      height: 115.73px;
      padding-right: 10px;
      img {
        width: 170.55px;
        height: 117.73px;
        border-radius: 4px;
        margin-right: 18px;
      }
      .activity_state {
        position: absolute;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 34px;
        text-align: center;
        color: #ffffff;
        top: -10px;
        left: -9px;
        width: 78px;
        height: 34px;
        border-radius: 5px 15px 15px 0;
      }
      .not_started {
        background: #4e93fb;
      }
      .have_in_hand {
        background: #ff7d18;
      }
      .closed {
        background: #999999;
      }
    }

    .storeCenter_item_top_left_flex {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .generalVersion {
        background: #448aff;
        border-radius: 2px;
        padding: 5px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        /* identical to box height */
        margin: 0 10px;
        color: #ffffff;
      }
      .mark_box {
        display: flex;
        img {
          width: 14px;
          // height: 14px;
          margin-right: 6px;
          color: #6e6e6e;
        }
        & > div {
          margin-left: 10px;
        }
      }
      .img_commer {
        display: flex;
        border: 1px solid #146aff;
        border-radius: 39px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #146aff;
        }
        .color_img {
          background: #146aff;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }
      .img_commers {
        display: flex;
        border: 1px solid #ff9c00;
        border-radius: 39px;
        margin: 0px 10px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #ff9c00;
        }
        .color_img {
          background: #ff9c00;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }

      h4 {
        color: #1f2d3d;
        font-size: 26px;
        font-weight: 500;
        margin: 0;
      }

      .storeCenter_item_top_left_flex_tag {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;
        padding-top: 13px;
        margin-bottom: 1px;
        width: 800px;
        span {
          box-sizing: border-box;
          display: block;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
        }

        .office {
          border: 1px solid #559eff;
          color: #4e93fb;
          padding: 1px 6px;
          margin-right: 10px;
        }

        .support {
          border: 1px solid #fd523f;
          color: #fd523f;
          margin-left: 12px;
        }
      }

      .address {
        font-size: 14px;
        span {
          padding: 0px 10px;
        }
      }
    }
  }
}
.accountManagement {
  width: 100%;

  /deep/ .el-tabs__item {
    font-size: 20px;
    color: #999999;
  }

  /deep/ .el-tabs__item.is-active {
    color: #4e93fb !important;
  }

  .table_data {
    .success {
      color: #ff7d18;
      font-size: 14px;
      font-weight: 400;
    }

    .error {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      p {
        color: #f53939;
        font-size: 14px;
        font-weight: 400;
      }

      .span {
        color: #999999;
        font-size: 12px;
        display: inline-block;
        width: 100%;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .new_span {
        color: #999999;
        line-height: 1.5em;
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
      }
    }

    .img {
      width: 42px;
      height: 42px;
      display: block;
      margin: 0 auto;
      border-radius: 4px;
    }

    .type_can {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #ff7d18;
        border-radius: 50%;
      }

      span {
        color: #ff7d18;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .type_xuan {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #001aff;
        border-radius: 50%;
      }

      span {
        color: #001aff;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .formal {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(78, 147, 251, 0.1);
      color: #4e93fb;
      border-radius: 2px;
    }

    .tourist {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 125, 24, 0.1);
      color: #ff7d18;
      border-radius: 2px;
    }

    .img_list {
      .new_img {
        width: 70px;
        height: 40px;
        border-radius: 4px;
        margin-right: 10px;
      }
    }

    .btn {
      width: 100%;
      display: flex;
      align-items: center;

      .pass {
        cursor: pointer;
        border: 1px solid #4e93fb;
        color: #4e93fb;
        height: 24px;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        line-height: 24px;
        margin-right: 10px;
        text-align: center;
      }

      .un_pass {
        border: 1px solid #fb6956;
        color: #fb6956;
        height: 24px;
        cursor: pointer;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        text-align: center;
        line-height: 24px;
      }
    }
  }

  .search {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .search_flex {
      display: flex;
    }
    .search_flex {
      display: flex;
      align-items: center;
    }
  }

  .search_right {
    width: 560px;

    /deep/ .el-button {
      border: 1px solid #448aff;
      color: #fff;
      border-radius: 0;
      background-color: #448aff;
    }
  }

  .new_page {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin: 20px 0;
  }

  .dialog_btn {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
